import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { ChefDetailProps } from './chef-detail.model';
import ChefDetailStyles from './chef-detail.module.scss';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import PostsDistributive from '../../components/posts-distributive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBirthdayCake, faConciergeBell, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

// FIXME: Add typing for Gatsby GraphQL queries.
// TODO: CREATE COMPONENT MODEL
// eslint-disable-next-line @typescript-eslint/no-explicit-any

const BasicTemplate: React.FC<any> = ({ data }): ReactElement => {
  const { t } = useTranslation();
  const siteTitle = data.site.siteMetadata.title;
  const chef = data.strapiChefs;
  const posts = data.allStrapiRecipes.edges;

  return (
    <Layout title={siteTitle}>
      <SEO title={chef.alias} description={chef.description} keywords={[chef.alias]} />
      <div className={ChefDetailStyles.section_profile}>
        <div className={ChefDetailStyles.profile_wrap}>
          <div
            className={ChefDetailStyles.author_image}
            style={{
              backgroundImage: `url(${chef.avatar ? chef.avatar.childImageSharp.fluid.src : ''})`,
            }}
          ></div>
          <h1>{chef.alias}</h1>
          <div className={ChefDetailStyles.author_social}>
            {chef.nationality && (
              <div className={ChefDetailStyles.location}>
                <FontAwesomeIcon className={ChefDetailStyles.global_svg} icon={faLocationArrow} size="1x" />
                <span>{chef.nationality.name}</span>
              </div>
            )}
            {chef.birthdate && (
              <div className={ChefDetailStyles.location}>
                <FontAwesomeIcon className={ChefDetailStyles.global_svg} icon={faBirthdayCake} size="1x" />
                <span>{chef.birthdate}</span>
              </div>
            )}
          </div>
          <div className={ChefDetailStyles.author_social}>
            {chef.instagram && (
              <div className={ChefDetailStyles.location}>
                <a
                  href={`https://www.instagram.com/` + chef.instagram}
                  target="_blank"
                  rel="noreferrer"
                  className={ChefDetailStyles.ig_profile}
                  aria-label={`instagram ${chef.alias}`}
                >
                  <FontAwesomeIcon className={ChefDetailStyles.global_svg} icon={faInstagram} size="1x" />
                  <span>{chef.instagram}</span>
                </a>
              </div>
            )}
          </div>
          <h2>{chef.description}</h2>
          <div className={ChefDetailStyles.posts_number}>
            <FontAwesomeIcon icon={faConciergeBell} size="lg" />
            <span>
              {data.allStrapiRecipes.totalCount} {t('BASIC_TEMPLATE_RECIPES_TITLE')}
            </span>
          </div>
        </div>
      </div>
      <div className={`${ChefDetailStyles.section_loop} ${ChefDetailStyles.wrap}`}>
        <div className={`${ChefDetailStyles.items_wrap} ${ChefDetailStyles.flex}`}>
          <PostsDistributive posts={posts} />
        </div>
      </div>
    </Layout>
  );
};

export default BasicTemplate;
export const pageQuery = graphql`
  query chefRecipes($id: String!, $strapiId: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    strapiChefs(id: { eq: $id }) {
      id
      alias
      name
      surname
      description
      instagram
      birthdate(formatString: "DD, MMM y", locale: "es")
      nationality {
        name
      }
      avatar {
        childImageSharp {
          fluid {
            src
          }
        }
      }
    }
    allStrapiRecipes(filter: { chef: { id: { eq: $strapiId } } }, sort: { order: DESC, fields: source___date }) {
      totalCount
      edges {
        node {
          title
          url_video
          published_at(formatString: "DD/MM/YYYY")
          chef {
            avatar {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
          fields {
            path
          }
          localImage {
            childImageSharp {
              fluid {
                src
              }
            }
          }
          with_custom_thumbnail
          custom_thumbnail {
            childImageSharp {
              fluid {
                src
              }
            }
          }
          categories {
            name
            path
            id
          }
          difficulty {
            name
          }
        }
      }
    }
  }
`;
